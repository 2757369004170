@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .selection-cell input[type="checkbox"],
  .selection-cell-header input[type="checkbox"] {
    --active: var(--primary-color);
    --active-inner: #fff;
    --focus: 2px rgba(var(--primary-color), 0.3);
    --border: #bbc1e1;
    --border-hover: var(--primary-color);
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    border-radius: 7px;
  }

  .selection-cell input[type="checkbox"]:after,
  .selection-cell-header input[type="checkbox"]:after {
    content: "";
    display: block;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }

  span svg.icon-react {
    width: 25px !important;
    height: auto !important;
    vertical-align: middle;
    display: inline-block;
  }

  .selection-cell input[type="checkbox"]:checked,
  .selection-cell-header input[type="checkbox"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  .selection-cell input[type="checkbox"]:disabled,
  .selection-cell-header input[type="checkbox"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }

  .selection-cell input[type="checkbox"]:disabled:checked,
  .selection-cell-header input[type="checkbox"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }

  .selection-cell input[type="checkbox"]:hover:not(:checked):not(:disabled),
  .selection-cell-header input[type="checkbox"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }

  .selection-cell input[type="checkbox"]:focus,
  .selection-cell-header input[type="checkbox"]:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  .selection-cell input[type="checkbox"]:not(.switch),
  .selection-cell-header input[type="checkbox"]:not(.switch) {
    width: 21px;
    border-radius: 7px;
  }

  .selection-cell input[type="checkbox"]:not(.switch):after,
  .selection-cell-header input[type="checkbox"]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }

  .selection-cell input[type="checkbox"]:not(.switch):checked,
  .selection-cell-header input[type="checkbox"]:not(.switch):checked {
    --o: 1;
    --r: 43deg;
  }

  .selection-cell input[type="checkbox"] + label,
  .selection-cell-header input[type="checkbox"] + label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 4px;
  }

  .selection-cell-header input[type="checkbox"]:indeterminate {
    --b: var(--active);
    --bc: var(--active);
    background: var(--b, var(--background));
    border-color: var(--bc, var(--border));
  }

  .selection-cell-header input[type="checkbox"]:indeterminate:after {
    width: 10px;
    height: 2px;
    background-color: var(--active-inner);
    top: 9px;
    left: 5px;
    transform: none;
  }
}

.selection-cell *,
.selection-cell-header * {
  box-sizing: inherit;
}

.selection-cell *:before,
.selection-cell *:after,
.selection-cell-header *:before,
.selection-cell-header *:after {
  box-sizing: inherit;
}

.map-instruction {
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0.5;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: "Nunito", sans-serif;
  margin-bottom: -35px;
  margin-left: 1px;
  transition: all 0.3s ease-in-out;
}

.map-instruction:hover {
  background-color: rgba(255, 255, 255, 1);
  opacity: 1;
}
.marker-container {
  transform: translate(-24px, -40px);
  cursor: pointer;
  position: relative;
}

.marker-info {
  position: absolute;
  top: -54px;
  background-color: white;
  color: black;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  font-family: "Nunito", sans-serif;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.marker-info.drop-off {
  right: 0;
}

.icon-container {
  border-radius: 9999px;
  padding: 5px;
  background-color: #f5f5f5;
}

.icon {
  border-radius: 9999px;
  padding: 3px;
  background-color: #e1e1e1;
  color: #4e5155;
  size: 14px;
}

.address-info {
  margin-left: 0.5rem;
}

.address-name {
  color: #1a1a1a;
}

.time-info {
  color: #6c757d;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.marker-icon {
  width: 50px;
  height: 50px;
  transition: transform 0.2s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.custom-icon {
  position: absolute;
  z-index: 10;
}

.default-icon {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  transform: translateY(-2px);
}

.marker-info:hover {
  background-color: #f5f5f5 !important;
  box-shadow: 0 0.7rem 1.2rem rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
}
.distance-matrix-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  transform: translate(-50%, -20%);
  display: flex;
  flex-direction: row;
  transition: all 250ms ease-in-out;
  gap: 0.25rem;
  color: white;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
}

.distance-matrix-overlay:hover {
  flex-direction: column;
  gap: 0;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -25%);
}

img[alt="Google"] {
  display: none;
}

a[rel="noopener"] {
  display: none;
}

div.gmnoprint {
  display: none;
}

.gm-style iframe + div {
  border: none !important;
}

.custom-info-window {
  background-color: rgba(45, 52, 54, 0.8);
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px 12px;
  font-family: "Nunito", sans-serif;
  font-size: 0.75rem;
  color: #ffffff;
  transition: transform 0.2s ease, opacity 0.2s ease;
  position: absolute;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  max-width: none;
  animation: fadeIn 0.2s ease-out forwards;
}

.custom-info-window.hidden {
  animation: fadeOut 0.2s ease-out forwards;
}

.custom-info-window:hover {
  background-color: rgba(45, 52, 54, 0.95);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -110%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -100%);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(-50%, -100%);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -110%);
  }
}

/* Autocomplete */
.pac-item-query {
  font-size: 0.875rem;
  display: block;
}
.pac-matched {
}

.pac-container {
  border-radius: 0px;
  background: white;
  border: 1px solid rgba(37, 40, 46, 0.1);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 0.3125rem;
  animation: slide-down-fade 0.3s ease-out both;
  font-family: "Nunito", sans-serif;
  animation-duration: 0.3s;
}
@keyframes slide-down-fade {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(1px);
  }
}
.pac-item {
  display: block;
  flex-direction: column;
  font-size: 0.725rem;
  border-radius: 1rem;
  border: 0.1px solid white;
  cursor: pointer;
  transition: background-color 0.1s ease;
  position: relative;
  padding-left: 3.125rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  &:before {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTEuMjkxIDIxLjcwNiAxMiAyMWwtLjcwOS43MDZ6TTEyIDIxbC43MDguNzA2YTEgMSAwIDAgMS0xLjQxNyAwbC0uMDA2LS4wMDctLjAxNy0uMDE3LS4wNjItLjA2M2E0Ny43MDggNDcuNzA4IDAgMCAxLTEuMDQtMS4xMDYgNDkuNTYyIDQ5LjU2MiAwIDAgMS0yLjQ1Ni0yLjkwOGMtLjg5Mi0xLjE1LTEuODA0LTIuNDUtMi40OTctMy43MzRDNC41MzUgMTIuNjEyIDQgMTEuMjQ4IDQgMTBjMC00LjUzOSAzLjU5Mi04IDgtOCA0LjQwOCAwIDggMy40NjEgOCA4IDAgMS4yNDgtLjUzNSAyLjYxMi0xLjIxMyAzLjg3LS42OTMgMS4yODYtMS42MDQgMi41ODUtMi40OTcgMy43MzVhNDkuNTgzIDQ5LjU4MyAwIDAgMS0zLjQ5NiA0LjAxNGwtLjA2Mi4wNjMtLjAxNy4wMTctLjAwNi4wMDZMMTIgMjF6bTAtOGEzIDMgMCAxIDAgMC02IDMgMyAwIDAgMCAwIDZ6Ii8+Cjwvc3ZnPgo=");
    height: 1rem;
    width: 1rem;
    object-fit: contain;
    position: absolute;
    left: 0.875rem;
    top: 40%;
    transform: translateY(-40%);
    filter: invert(50%) sepia(0%) saturate(0%) brightness(75%) contrast(100%);
  }
  &:hover {
    background-color: #bfbfbf;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pac-item-selected {
  &:before {
    filter: invert(38%) sepia(80%) saturate(600%) hue-rotate(-10deg) brightness(120%) contrast(110%);
  }
}

.pac-item:hover {
  background-color: #f1f1f1;
}

.pac-icon {
  display: none;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}
.regionDetails {
  transform: scale(0.75); /* Adjust the scale as needed */
  transform-origin: top right; /* Keeps the position relative to the top right corner */
  position: absolute; /* Ensure it stays in the correct position */
  top: 0; /* Adjust as needed */
  right: 0; /* Adjust as needed */
  z-index: 1000; /* Ensure it overlays other elements */
}
.smaller-scale-autocomplete {
  transform: scale(0.75) !important; /* Adjust the scale as needed */
  transform-origin: top left !important; /* Keeps the position relative to the top right corner */
  margin-top: -9px !important;
}
.small-scale {
  font-size: 0.8125em; /* Adjust as needed */
}

.small-scale .app-brand-logo {
  height: 24px; /* Adjust as needed */
  width: 24px; /* Adjust as needed */
}

.small-scale .sidenav-text {
  font-size: 0.8125em; /* Adjust as needed */
}
.navbar {
  padding: unset;
}
.accordion > .card {
  border-radius: 0.5rem !important;
}
.unset-svg > .icon-inner,
.unset-svg .ionicon,
.unset-svg svg {
  display: unset !important;
  width: unset !important;
  height: unset !important;
}
iframe {
  display: none;
}

.iframe-container iframe {
  display: unset;
}

/* START: Top brand styles */
.light-style .sidenav .app-brand.demo {
  height: 49px;
  justify-content: space-between;
}

.dark-style .sidenav .app-brand.demo {
  height: 49px;
}

.material-style .sidenav .app-brand.demo {
  height: 70px;
}

.app-brand-logo.demo {
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.app-brand-logo.demo svg {
  width: 20px;
  height: 11px;
}

.app-brand-text.demo {
  font-size: 1.1rem;
}

.layout-1 .layout-sidenav .app-brand.demo,
.layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
.layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
  display: none;
}

.layout-1 .layout-navbar .app-brand.demo,
.layout-reversed .layout-2 .layout-navbar .app-brand.demo {
  display: -ms-flexbox !important;
  display: flex !important;
}

.layout-1 .layout-navbar .layout-sidenav-toggle,
.layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle,
.layout-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle {
  display: block !important;
}

@media (min-width: 992px) {
  .layout-offcanvas .layout-sidenav .layout-sidenav-toggle,
  .layout-fixed-offcanvas .layout-sidenav .layout-sidenav-toggle {
    display: none;
  }
}
/* END: Top brand styles */
/*Theme specific styles */
.popover {
  max-width: 350px;
}

//align page title correctly on mobile devices
@media screen and (max-width: 600px) {
  .page-title {
    flex: 1;
    text-align: center;
  }
}
//popups should be mid modals
.modal-header .close,
.modal-slide .close {
  font-size: 34px;
  font-weight: bold;
}

.swal2-shown .swal2-container {
  z-index: 1290 !important;
}

.swal2-modal {
  margin-top: 100px;
}

.swal2-modal.swal2-popup .swal2-actions .btn {
  margin-left: 15px;
  margin-right: 15px;
}

.form-control-sm {
  height: calc(1.5em + 0.376rem + 4px) !important;
}

// .swal2-center .swal2-modal { margin-top: -150px; }

.cbs-dispatch .sidenav-vertical,
.cbs-dispatch .sidenav-vertical,
.cbs-dispatch .sidenav-vertical .sidenav-block,
.cbs-dispatch .sidenav-vertical .sidenav-inner > .sidenav-item,
.cbs-dispatch .sidenav-vertical .sidenav-inner > .sidenav-header {
  width: 11.5rem;
}

// .cbs-dispatch .sidenav-vertical .app-brand {
//   padding-left: 5px;
// }

.cbs-dispatch .app-brand-text {
  width: 10.1rem;
  text-align: center;
  //max-width: 165px;
}

.cbs-dispatch .table td {
  padding: 10px 5px;
}

.text-warning {
  color: #f8ac59 !important;
}

.badge-warning {
  background-color: #f8ac59;
  color: #ffffff;
}

.btn-link:hover,
.btn-link:focus {
  color: #3c8ae2;
  text-decoration: none;
  box-shadow: none;
}

//https://stackoverflow.com/a/62870548/334207
@for $i from 1 through 50 {
  $zIndexBackdrop: #{1086 + (5 * $i)};
  $zIndexContent: #{1086 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

.no-wrap {
  white-space: nowrap;
}

.fs-20 {
  font-size: 20px;
}
.fs-18 {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px;
}

.modal-95w {
  max-width: 95% !important;
}

.modal-80w {
  max-width: 80% !important;
}

.modal-85w {
  max-width: 85% !important;
  margin: 8px auto;
}

.modal-70w {
  max-width: 70% !important;
}

@media (max-width: 768px) {
  .modal-70w {
    max-width: unset !important; // Unset max-width on mobile
  }
}

.modal-65w {
  max-width: 65% !important;
}

.modal-60w {
  max-width: 60% !important;
}

.modal-50w {
  max-width: 50% !important;
}

.modal-40w {
  max-width: 40% !important;
}

/*show autocomplete correctly in modals */
.pac-container {
  z-index: 20000;
}

/*#region Mobile-Friendly Table */
/* Mobile-Friendly Table */
@media screen and (max-width: 600px) {
  table.mobile-friendly thead {
    display: none;
  }
  table.mobile-friendly td {
    display: flex;
  }

  table.mobile-friendly.with-labels td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }

  .text-lg {
    font-size: 13px !important;
  }

  .text-base {
    font-size: 11px !important;
  }

  .details__line {
    margin: 0px 16px !important;
  }
  .modal-85w {
    max-width: 100% !important;
  }

  .details__car-image-wrapper {
    width: 50vw !important;
    max-width: 512px !important;
    margin: 0px auto;
  }
}

/* USED BY DISPATCH SCREEN TABLE TO BE MOBILE FRIENDLY */
@media (max-width: 768px) {
  .mobile-collapse .card {
    border: none;
  }

  /* Ensure the table layout is full width */
  .mobile-collapse .table {
    width: 100%;
    border: 0;
  }

  /* Hide the table headers on mobile */
  .mobile-collapse .table thead {
    display: none;
  }

  .mobile-collapse .table-row-card .table {
    margin-top: 0;
  }

  /* Make table rows display as flex containers */
  .mobile-collapse .table tbody tr {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
    /* Adds some space between the rows */
    border-bottom: 1px solid #ddd;
    /* Adds a slight border to each row for clarity */
  }

  /* Style for each cell to make it full width */
  .mobile-collapse .table td {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left !important;
    /* Override any text alignment */
    border-top: none !important;
    /* Remove the top border from cells */
    /* Add some padding and maybe a slight border or background to distinguish the cells */
    padding: 10px;
    border-bottom: 1px solid #efefef;
    /* Slight border for each cell */
    background-color: #f9f9f9;
    /* Light background for the cells */
    border-bottom-left-radius: 1rem;
    border-width: 0;
  }

  /* Additional styling to differentiate sections */
  .mobile-collapse .table td:first-child {
    background-color: #f1f1f1;
    /* Slightly darker background for the first cell of each row */
  }

  .mobile-collapse .table > tbody:last-child > tr:last-child > td:first-child,
  .mobile-collapse .table-row-card .table tbody tr td:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .mobile-collapse .table > tbody:last-child > tr:last-child > td,
  .mobile-collapse .table > tbody:last-child > tr:last-child > td:first-child {
    border-bottom-left-radius: 0;
  }

  .details__car-image-wrapper {
    width: 50vw !important;
    max-width: 512px !important;
    margin: 0px auto;
  }
}

/* USED BY DISPATCH SCREEN TOP MENU TO BE MOBILE FRIENDLY */
@media (max-width: 768px) {
  .top-menu {
    flex-direction: column;
  }

  .search-container {
    justify-content: center;
    /* This will center the search bar on the screen */
    margin-bottom: 10px;
    align-items: normal;
    /* Adds some space between the search bar and the following content */
  }

  .search-container .form-control-sm {
    width: 100%;
    /* Forces the search bar to take the full width of the container */
  }

  .datepickers-container {
    flex-direction: row;
    /* Aligns datepickers and the refresh button in a row */
    flex-wrap: wrap;
    /* Allows items to wrap into the next row if needed */
    justify-content: space-between !important;
    /* Spaces out the datepickers and refresh button */
    margin-bottom: 10px;
    /* Adds some space below the datepickers */
  }

  .datepickers-container .cbs-datepicker-inline {
    flex-basis: 40%;
    margin: 0 !important;
  }

  .datepickers-container > button {
    margin-top: 26px;
  }

  .other-controls {
    flex-direction: row;
    /* Aligns the remaining controls in a row */
    flex-wrap: wrap;
    /* Allows items to wrap if needed */
    justify-content: space-between;
    /* Spaces out the controls evenly */
  }

  .other-controls > label {
    display: none !important;
  }
  .other-controls .number-of-hours {
    display: flex !important;
  }
  .other-controls .configuration-dropdown {
    flex-basis: 30% !important;
    text-align: center;
  }

  .other-controls > div {
    margin-bottom: 10px;
    /* Adds some space between the wrapped items */
  }

  /* Ensure that dropdowns and toggle buttons are full width on their own row */
  .other-controls .dropdown,
  .other-controls .btn-group-sm {
    flex-basis: 100%;
  }
}
/* END: Mobile-Friendly Table */
/*#endregion*/

.cbs-datepicker-inline .react-datepicker-wrapper {
  display: inline-block;
  margin-left: 5px;
}

/* END: Theme specific styles */

/* Page styles */
.page-header {
  background-color: white;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 0;
}

/* Align text inside buttons */
ion-icon {
  font-size: 20px;
  vertical-align: middle;
  pointer-events: none;
}
/* Align text inside buttons */
button span {
  vertical-align: middle;
}

.booking-status {
  font-size: 13px;
  padding: 12px;
  border-radius: 35px;
  padding: 6px;
}

/* Phone input styles */
.react-tel-input .form-control {
  min-height: 38px;
  border-radius: 0.5rem !important;
  width: 100% !important;
}
.flag-dropdown {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

/* End: Phone input styles */

/* Table style enahancements */
.react-bootstrap-table .row-warning {
  color: white;
  background-color: #ef8a8780;
}

.react-bootstrap-table .row-info {
  color: white;
  background-color: #007bff80;
}

@media (max-width: 768px) {
  .react-bootstrap-table.table-row-card .table tbody tr:nth-child(even) {
    background-color: #e5e8e880; /* or #ededed*/
  }
}

.react-bootstrap-table.table-row-card .table tbody tr:nth-child(even) td {
  background-color: #e5e8e880; /* or #ededed*/
}

.checkbox-dropdown .dropdown-item:not(.disabled):active {
  background-color: initial;
  color: initial;
}

.currency-selector {
  display: inline-block !important;
  min-width: 320px;
  height: calc(1.54em + 0.876rem + 2px);
  padding: 0.438rem 0.875rem;
  font-size: 0.894rem;
  font-weight: 400;
  line-height: 1.54;
  color: #4e5155;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(24, 28, 33, 0.1);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-bootstrap-table {
  min-height: 350px;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ff6a00;
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: #ff6a00;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-bottom: none;
  color: #ff6a00;
  background-color: transparent;
  margin-right: 2px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.nav-tabs {
  // border-bottom: 1px solid #ff6a00;
}
:fullscreen,
::backdrop {
  background-color: white;
}

.gm-style iframe + div {
  border: none !important;
}

.info-window-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.info-window-header h4 {
  margin: 0;
  flex-grow: 1;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}
.gm-style-iw button.gm-ui-hover-effect {
  display: none !important;
}

.rounded-table {
  border-collapse: separate;
  border-spacing: 0 4px;
}

.rounded-table tbody tr {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

// BOOKING DETAILS
.object-contain {
  object-fit: contain;
}

.text-xl {
  font-size: 18px;
}

.text-lg {
  font-size: 16px;
}

.text-base {
  font-size: 14px;
}

.text-sm {
  font-size: 11px;
}

.font-semibold {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.text-gray-500 {
  color: #a1a1a1;
}

.text-gray-600 {
  color: #696969;
}

.text-gray-700 {
  color: #252525;
}

.header__route__line {
  border: 1px solid rgb(255, 229, 181);
  border-radius: 5px;
  position: relative;
}

.header__flight__line {
  border: 2px solid rgb(255, 229, 181);
  border-radius: 5px;
  position: relative;
}

.header__route__line::after {
  content: " ";
  display: block;
  background: rgb(255, 184, 52);
  height: 4px;
  width: 8vw;

  position: absolute;
  bottom: -2px;
  left: -2px;
  border-radius: 5px;
}

.header__route__line::before {
  content: " ";
  display: block;
  background: rgb(255, 184, 52);
  height: 4px;
  width: 8vw;

  position: absolute;
  bottom: -2px;
  right: -2px;
  border-radius: 5px;
}

.details__line {
  width: 1px;
  min-height: 356px;
  height: 100%;
  margin: 0px 32px;
  background-color: gainsboro;
}

.details__car-image-wrapper {
  width: 18vw;
  max-width: 512px;
}
